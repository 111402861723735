

.carousel-inner {
  max-height: 100vh;
}
.carousel-item {
  position: relative;
  
}

.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0));
}

.carousel-item img{
    max-height: 95vh;

}

.carousel-caption {
  position: absolute;
  font-family: 'Prompt';

  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  padding-left: 5%;
  color: #fff;
  text-align: left;
}

.carousel-caption h5{
  font-size: 70px;
  padding-bottom: 2%;
  font-family: 'The Friday Stroke Regular';

}

.carousel-caption p {
  font-size: 15px;
  width: 50%;
  font-weight: 100;
}

.carousel-caption h5,
.carousel-caption p {
  margin: 0;
}
.menu {
  border: 1px solid #2e2e2e;
  height: 100%;
  width: 100%;
  background-color: #2e2e2e;
  overflow: hidden;
  color: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

#g1{
  display: none;
}

.ViewMoreButton1{
  margin-top:3%;
  }

  .bottom-button2{
    border-radius: 5px;
    background: #318A2A;
    border-style: none;
    padding: 10px 30px 10px 30px;
    color: #FFF;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    }
    
@media screen and (max-width: 768px) {

  .menu {
    height: auto;
  }

  .row {
    flex-wrap: wrap;
  }

  .col-sm-6,
  .col-md-3 { 
    width: 50%;
  }

  .carousel-caption{
    padding-left: 5%;
  }

  .carousel-caption h5{
    font-size: 45px;
    padding-bottom: 2%;
  }
  .ViewMoreButton1{
    margin-top:7%;
    }
  
  .carousel-caption p {
    margin-top: 3%;
    font-size: 15px;
    line-height: 18px;
    width: 100%;
  }
  
}


@media screen and (max-width: 480px) {
  .menu{
    width: 100%; 
    height: auto;
    overflow-x: auto; 
    white-space: nowrap;
    display: flex;
    flex-direction: column;
  }

  .menu svg {
    width: 22px;
  }

  .menu p {
    font-size: 12px;
    text-wrap: wrap;
  }
  

  .banner-container {
    width: 100vw;
    height: 80vh;
  }

  .carousel-inner {
    width: 100%;
    height: 100vh;
  }

  .carousel-item {
    width: 100%;
    height: 80vh;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}



