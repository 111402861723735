 




.con{
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    
  }
  .hover-fx {
    font-size: 36px;
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    transition: 300ms;
  }
  
  .hover-fx:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: "";
    box-sizing: content-box;
    box-shadow: 0 0 0 3px #fff;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 300ms;
  }
  
  .hover-fx:hover {
    background-color: #fff;
    color: #00989a;
  }
  
  .hover-fx:hover:after {
    opacity: 1;
    transform: scale(1.15);
  }
  
  /* --------footer contact------- */
  
  .contact-form-wrapper {
    padding: 0px 0;
  }
  .contact {
    display: flex;
    justify-content: center;
  }
  .contact-form {
    padding: 30px 40px;
    background-color: #f5f5f5;
    border-radius: 0px;
    height: auto;
    margin-top: 0%;
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .contact-form .form-input
   {
    background-color: #ffffff;
    height: 60px;
    padding-left: 16px;
  }
  .form-text-area{
    background-color: #f0f4f5;
    height: 100px;
    padding-left: 16px;
  }
  
  .contact-form .form-text-area {
    background-color: #ffffff;
    height: auto;
    padding-left: 16px;
  }
  
  .contact-form .form-control::placeholder {
    color: #aeb4b9;
    font-weight: 500;
    opacity: 1;
    font-size: 12px;
  
  }
  
  .contact-form .form-control:-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control::-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control:focus {
    border-color: #025721;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #025721;
  }
  
  .contact-form .title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: rgb(68, 142, 68);
  }
  
  .contact-form .description {
    color: #aeb4b9;
    font-size: 14px;
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper {
    text-align: center;
  
  }
  
  .contact-form .submit-button-wrapper input {
    border: none;
    border-radius: 4px;
    background-color: #025721;
    color: white;
    text-transform: uppercase;
    margin: 20px 0px 0px 0px;
    padding: 15px 80px;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 14px;
  
  }
  
  .contact-form .submit-button-wrapper input:hover {
    background-color: #e5dbe0;
    color: #025721;
    transition: background-color 0.3s;
  
  }
  .contact-form .submit-button-wrapper input:not(:hover) {
    transition: background-color 0.3s;
  }
  .mess{
    height: auto;
    margin: 0px 0px 25px 0px;
    border-bottom: 1px solid black;
  }
  .success-message{
    color: #025721;
  font-size: 14px;
  display: flex;
  margin: 5px 0px;
  justify-content: center;
  }
  .address-1{
    text-transform: uppercase;
    margin: 0px 100px 50px 100px;
    padding: 50px 50px;
    background-color: #f0f4f5;
    border-radius: 8px;
  }
  .address-1 h6{
    color: #025721;
  }

  .address-1 h6 a{
    color: #025721;
    text-decoration: none;
  }
  .address-1 p{
    color: rgba(70, 67, 67, 0.8);
  
  }

 
  .right{
    text-transform: lowercase;
    justify-content: right;
    text-align: right;
  }
  @media (max-width: 768px) {
  .con{
    margin: 10px;
  }
  .contact-form {
    padding: 0px 10px;
    background-color: #f5f5f5;
    border-radius: 0px;
    height: 450px;
  }
  
  .mess{
    height: auto;
    margin: 0px 0px 0px 0px;
    padding-bottom: 55px;
    border: none;
  }
  .address-1{
    margin: 0px 10px 50px 10px;
    padding: 30px 10px;
    background-color: #f0f4f5;
    border-radius: 8px;
    font-family: 'Prompt';
  }
  .address-1 h6{
  font-size: 10px;
  }
  .address-1 p{
    color: rgba(70, 67, 67, 0.8);
    font-size: 14px;
  }

  .right{
  text-align: left;

}
  }
  