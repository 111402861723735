/* Common styles */
.header {
  overflow: hidden;
  position: absolute;
  z-index: 9999;
  height: auto;
  display: flex;
  height: 70px;
}

.logo {
  font-size: 13px;
  cursor: pointer;
}

/* Toggle menu */
.navbar-brand {
}

.main-logo {
  z-index: 999;
}

.mega-menu {
  font-size: 14px;
  position: absolute;
  z-index: 2;
  display: flex;
}
.container {
  color: rgba(64, 25, 14, 0.9);
  font-size: 15px;
  padding: 0;
}

.container::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 50px;
  background-color: rgba(255, 255, 255, 0.96);
  z-index: -1;
  border-radius: 20px;
}





.menu-icon svg {
  font-size: 40px;
  position: fixed;
  display: none;
  float: right;
}


/* Desktop specific styles */
@media screen and (min-width: 993px) {
  .menu--right {
    display: none;
  }
}

/* Tablet specific styles */
@media screen and (max-width: 992px) {
  .mega-menu {
    display: none;
  }

  .menu--right {
    display: block;
  }
}

/* Mobile specific styles */
@media screen and (max-width: 768px) {
  .menu--right {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 12%;

  }

 .helo{
  overflow-y: scroll;
  max-height: 630px !important;
 }
 /* #update{
  margin-top: -15%;
 } */
  .logo1 {
    font-size: 14px;
    cursor: pointer;
  }

  .logo1 img{
    width: 80%;
  }
 
  .menu-popup {
    padding: 20px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
     
  }

  #scroll {
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .head-side {
    margin-top: 1%;
    font-size: 20px;
    padding-top: 5%;

  }

  .menu-item1 {
    padding-left: 30px;
    padding: 10px;
  }
  


  .popup-close {
    position: absolute;
    right: 20px;
    top: 20px;
    float: right;
  }



  .menu-icon svg {
    font-size: 30px;
    position: fixed;
    display: block;
    float: right;
  }

  .menu-item1 svg{
    float: right;
    margin-top: 1%;
  }

  
  .menu-item1::before {
    content: '';
    display:block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transprant;
    transform-origin: center;
    transition: transform .3s ease-in-out; /* Adjust the duration here */
    z-index: -1;
  }
  
  .menu-item1:hover::before {
  content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #498e43;
        border-radius: 20px;
  
    transform: rotateX(.5turn);
  }
  
  .menu-item1 {
    font-size: 14px;
    letter-spacing: 2px;
     padding: 10px;
     line-height: 18px;
    position: relative;
  }

  
}
@media screen and (max-width: 480px) {
  .helo{
    overflow-y: scroll;
    max-height: 480px !important;
   }
}
@media screen and (max-width: 320px) {
  .menu--right {
    display: block;
  }
  
  .helo{
    overflow-y: scroll;
    max-height: 630px !important;
   }
}

