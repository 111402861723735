.DreamTour{
  margin-top: 3%;
  margin-bottom: 70px;
  overflow-x: hidden;
  padding-top: 3%;
  padding-bottom: 3%;
}
.tour-dreams {
  margin-top: 2%;
  font-weight: 400;
  margin-bottom: 2%;
}

.normal-text{
  display: flex;
  color: #282828;
  font-family: Prompt;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  justify-content: center;
  
}
.colGreen{
  color: #386B33;
  font-family: 'The Friday Stroke Regular';
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.colGreen{
  display: flex;
  flex-direction: column;
  padding-left:10px;
}

.LineSVG{
margin-top: -30px;
align-self: flex-end;
}

.Tourdreams{
  width: 100%;
}

.image-Container {
  position: relative;
}

.ImageFill {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease, transform 0.5s ease;
}

.FillCircle {
  position: absolute;
  top: 7%;
  left: 7%;
  opacity: 0;
  width: 85%;
  z-index: -1;
}

.image-Container:hover .FillCircle {
  opacity: 1;
}

.ImageRank{
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}
.rankName{
  font-family: 'The Friday Stroke Regular';
    color: #2B2B2B;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 20px;
}
.Rank {
  /* position: absolute; */
  /* top: 10%; */
  /* left: 40%; */
  /* transform: translate(-50%, -50%); */
  justify-content: center;
  align-items: center;
  display: flex;
 margin-left: -50px;
 margin-top: 20px;
}
.RotateOnHover{
  z-index: 9999;
}
.RotateOnHover:hover {
  transform: rotate(-90deg);
}
.ImageDescription{
  color: #282828;
  text-align: center;
  font-family: Prompt;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 999px) {
  .normal-text {
    display: block;
    font-size: 30px;
  }
  .colGreen {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    
  }
    .ImageRank {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

    }
}
@media (max-width: 767px) {
  .normal-text {
    display: block;
    font-size: 30px;
  }

  .colGreen {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .ImageRank {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }
    .ImageDescription {
      width: 100%;
    }

  
}
@media (max-width: 700px) {
  .colGreen {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 600px) {
  .colGreen {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
}
    @media (max-width: 500px) {
      .colGreen {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
      }
}

@media (max-width: 400px) {
  .colGreen {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 360px) {
  .colGreen {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}