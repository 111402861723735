@import url('https://fonts.googleapis.com/css2?family=Halant&family=Prompt&display=swap');


@font-face {
    font-family: 'The Friday Stroke Regular';
    font-style: normal;
    font-weight: normal;
    src: local('The Friday Stroke Regular'), url('../font/the-friday-stroke-cufonfonts-webfont/The\ Friday\ Stroke\ Font\ by\ 7NTypes.woff') format('woff');
} 


.next {
    padding-top: 20px;
    padding-bottom: 5%;
    background-image: url('../../../public/images/upcoming-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    object-fit: cover;
    /* height: 100vh; */
}
.img-container2 {
    width: 240px;
    height: auto;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    
}
.tripImg-container{
    text-align: center;
}

.img-container2 .image1 {
    width: 240px;
    height: 240px;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover;
}

.priceTag{
    /* justify-content:space-between; */
    display: flex;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    
}

.Tag1{
    border-radius: 5px 0px;
    background: #498E43;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 5px 15px 5px 15px;
    color: #FFF;
    font-family: Prompt;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    /* left: 0; */
}
.Tag2 {
    border-radius: 0px 5px;
    background: #498E43;
    box-shadow: 0px 4px 7px px rgba(0, 0, 0, 0.25);
    padding: 5px 23px 9px 23px;
    color: #FFF;
    position: absolute;
    right:0px;
    
}
.Image_Title{
    position: absolute;
    top:73%;
    
}
.Image_Title img{
    width: 100%;
    margin-left: -20px;
}

.Image_Title1 {
    position: absolute;
    top: 75%;
}
.ImageText{
    padding-left: 5px;
    color: #FFF;
    font-family: 'The Friday Stroke Regular';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.next-trip{
    width: 230px;
    height: 230px;
}

.ViewMoreButton{
margin-top:5%;
text-align: center;
}
.next-trips-duration1{
    color: #0B4507;
    font-weight: 600;
    font-size: 20px;
    align-content: center;
    margin-top: 10%;
}
.bottom-button1{
border-radius: 5px;
background: #318A2A;
border-style: none;
padding: 10px 30px 10px 30px;
color: #FFF;
font-family: Prompt;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.next-trip .main-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
}
.next-trip-img{
    text-align: center;
}

.icons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 13%;
    z-index: 9;
    position: relative;
}




.bus img{
    width: 30px;
    height: 18px;
    align-items: center;
    justify-content: center;
}

.flight span{
    color: #ffffff;
}

.vector{
    position: relative;
    bottom: 10%;
    z-index: 9;
    right: 6%;
    width: 100%;
    display: flex;
    align-items: center;
}

.vector img{
    position: relative;
    width: 100%;
}

.vector span{
    position: absolute;
    right: 8%;
    color: #ffffff;
    font-size: 22px;
    font-family: 'The Friday Stroke Regular';
    text-align: left;
    padding-left: 15%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
}

.next-duration {
    color: #0B4507;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.normal-text1 {
    display: flex;
    color: #000;
    font-family: Prompt;
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    justify-content: center;

}
.colGreen1{
    color: #386B33;
    font-family: 'The Friday Stroke Regular';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: -10px;
}

/* .colGreen1 {
    display: flex;
    flex-direction: column;
} */
.LineSVG1 {
    margin-top: -35px;
}
.LineSVG1 img{
    width: 80%;
}
.Small img{
    width: 70%;
    height: auto;
}

@media (max-width: 1024px) {
    .normal-text1 {
        display: flex;
        font-size: 40px;
    }

    .colGreen1 {
        font-size: 40px;
        margin-left: -30px;
    }

    .LineSVG1 {
        margin-left: 20px;
        margin-top: -15px;
    }

    .LineSVG1 img {
        width: 100px;
    }
        .img-container2 .image1 {
            width: 200px;
            height: 200px;
            border-radius: 5px;
            cursor: pointer;
            object-fit: cover;
        }
    
        .img-container2 {
            width: 200px;
        }
}
@media (max-width: 767px) {
.normal-text1{
    display: flex;
    font-size: 30px;
  }

  .colGreen1 {
    font-size: 30px;
    margin-left:-40px;
  }
.LineSVG1{
    margin-left: 20px;
    margin-top: -10px;
}
.LineSVG1 img{
    width: 100px;
}
.img-container2 .image1 {
    width: 230px;
    height: 230px;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover;
}
.img-container2 {
    width: 230px;
}
}
@media (max-width: 700px) {
.normal-text1 {
        display: flex;
        font-size: 30px;
    }

    .colGreen1 {
        font-size: 30px;
        margin-left: -30px;
    }

    .LineSVG1 {
        margin-left: 20px;
        margin-top: -10px;
    }

    .LineSVG1 img {
        width: 100px;
    }
}
@media (max-width: 600px) {
.normal-text1 {
        display: flex;
        font-size: 30px;
    }

    .colGreen1 {
        font-size: 30px;
        margin-left: -30px;
    }

    .LineSVG1 {
        margin-left: 20px;
        margin-top: -10px;
    }

    .LineSVG1 img {
        width: 100px;
    }
}
    @media (max-width: 500px) {
    .normal-text1 {
            display: flex;
            font-size: 30px;
        }
    
        .colGreen1 {
            font-size: 30px;
            margin-left: -30px;
        }
    
        .LineSVG1 {
            margin-left: 20px;
            margin-top: -10px;
        }
    
        .LineSVG1 img {
            width: 100px;
        }
}

@media (max-width: 400px) {
.normal-text1 {
        display: flex;
        font-size: 30px;
    }

    .colGreen1 {
        font-size: 30px;
        margin-left: -30px;
    }

    .LineSVG1 {
        margin-left: 20px;
        margin-top: -10px;
    }

    .LineSVG1 img {
        width: 100px;
    }
}
@media (max-width: 360px) {
.normal-text1 {
        display: flex;
        font-size: 30px;
    }

    .colGreen1 {
        font-size: 30px;
        margin-left: -30px;
    }

    .LineSVG1 {
        margin-left: 20px;
        margin-top: -10px;
    }

    .LineSVG1 img {
        width: 100px;
    }
}
