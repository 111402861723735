  

.headline_summary{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    font-family: 'Prompt';
}
.loading-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-size: 20px;
    font-weight: bold;
  }
  

.name {
    font-family: 'The Friday Stroke Regular';
  font-style: normal;
  position: relative;
  color: #4e7300;

  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 10%;
}

.name span {
  transform: rotate(-deg);
  position: absolute;
  z-index: 1;
  margin: -30px;
}
.headline{
  text-align: center;
  margin-bottom: 40px;
}
.headline p{
  letter-spacing: 2px;
  padding-top: 25px;
}
.containers{
  padding-top:10vh;
}
.breadcrumb{
display: flex;
justify-content:center ;}

.breadcrumb:not(:first-child) {
  margin-top: 30px;
}
.breadcrumb_link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: var(--color-text);
  text-decoration: none;
}
.breadcrumb_link:not(:first-child) {
  margin-left: 8px;
}
.breadcrumb_link:hover {
  color:rgb(68, 142, 68);;
}
.breadcrumb_link_icon {
  flex: 0 0 auto;
  margin-left: 8px;
  font-size: 13px;
}
.breadcrumb_active {
  margin-left: 8px;
  white-space: nowrap;
  color: rgb(68, 142, 68);;
}
.mission h3{
  text-align: center;
  color: rgb(68, 142, 68);;
}
.section2{
padding: 30px 10px ;
background-color: rgb(245, 245, 245);
}
.mission{
  margin: 20px 0px 20px 0px;
}

.mission .off-head{
    font-size: 30px;
    font-family: 'The Friday Stroke Regular';

}
.mission h4{
  font-family: 'Prompt';
  color:  #025721;
font-size: 20px;
padding: 0px 0px 10px 0px;
}
.mission p{
  font-size: 15px;
  color: rgb(68, 142, 68);;
  text-align: center;
  font-family: 'Prompt';
}


.mission2{
  padding: 0px 50px;
}
.mission2 h6{
  font-size: 16px;
  color: rgba(70, 67, 67, 0.8);}
.section:not(:first-child) {
  opacity: 0;
  transition: opacity 1s ease;
}

/* Ensure the first section is fully visible by default */
.section:first-child {
  opacity: 1;
}

.offer-icons{
    width: 50%;
}
.mission2 .offertext{
  font-family: 'Prompt';
  font-size: 14px;
}
@media screen and (max-device-width: 992px) {
  
.mission2{
  padding: 0px 20px;
}
}
@media screen and (max-device-width: 768px) {
  .mission2{
  padding: 0px 10px;
}
.mission{
  display: block;
}
.mission h4{
  color:  #025721;;
font-size:16px;
padding: 20px 0px 0px 0px;
}
.mission p{
  font-size: 14px;
  color: rgb(68, 142, 68);;

}
}
/* styles.css */

/* Common styles for all screen sizes */
.section3 {
  margin:0px 20px;
}

/* Use Flexbox to make the images responsive */
.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mass {
  flex-basis: calc(33.33% - 20px);
  margin: 20px 0px;
}

.tag-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 6px;
}

/* Responsive styles using media queries */
/* Adjust the layout for screen sizes below 768px */
@media (max-width: 768px) {
  .mass {
    flex-basis: calc(50% - 20px);
  }
}

/* Adjust the layout for screen sizes below 480px */
@media (max-width: 480px) {
  .mass {
    flex-basis: 100%;
  }
}

