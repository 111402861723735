@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;0,500;0,600;0,700;1,300;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=The+Friday+Stroke:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Eras+Demi+ITC:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@400&display=swap");

body {
  margin: 0;
  line-height: normal;
  font-family: Prompt;
}

:root {
  /* fonts */
  --font-eras-demi-itc: "Eras Demi ITC";
  --font-prompt: Prompt;
  --font-inknut-antiqua: "Inknut Antiqua";
  --font-the-friday-stroke: "The Friday Stroke";

  /* font sizes */
  --font-size-smi: 13px;
  --font-size-mini: 15px;
  --font-size-lg: 18px;
  --font-size-3xl: 22px;
  --font-size-xs: 12px;
  --font-size-mid: 17px;
  --font-size-12xl: 31px;
  --font-size-5xl: 24px;
  --font-size-xl: 20px;
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --font-size-11xl: 30px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #818181;
  --color-gray-200: #262626;
  --color-gray-300: #231f20;
  --color-gray-400: #1b1b1b;
  --color-darkslategray-100: #3d3d3d;
  --color-darkslategray-200: #3c3c3c;
  --color-darkslategray-300: #303030;
  --color-whitesmoke: #f8f8f8;
  --color-dimgray: #6c6c6c;
  --color-darkolivegreen-100: #386b33;
  --color-darkolivegreen-200: rgba(56, 107, 51, 0.22);
  --color-black: #000;

  /* Border radiuses */
  --br-6xl: 25px;
  --br-10xs: 3px;
  --br-base: 16px;
  --br-9xs: 4px;
  --br-8xs: 5px;
  --br-6xs: 7px;
}