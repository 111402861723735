@import url('https://fonts.googleapis.com/css2?family=Halant&family=Prompt&display=swap');


@font-face {
    font-family: 'The Friday Stroke Regular';
    font-style: normal;
    font-weight: normal;
    src: local('The Friday Stroke Regular'), url('../font/the-friday-stroke-cufonfonts-webfont/The\ Friday\ Stroke\ Font\ by\ 7NTypes.woff') format('woff');
} 


.hello {
    /* padding: 5%; */
  
    padding-top: 20px;
    padding-bottom: 5%;
    overflow-x: hidden;
    background-image: url(../img/why-choose-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Popular-head {
    padding-left: 4%;
    padding-right: 4%;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}

.Popular-head h2 {
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 40px;

}

.Popular-head img {
    float: right;
    width: 40%;
}

.Popular-head .trips-1 {
    color: #386B33;
    font-family: 'The Friday Stroke Regular';
    font-weight: 400;
}

.Choose-head {
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 4%;
    width: 42%;
    margin-left: auto;
    margin-right: auto;
}

.Choose-head h2 {
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 40px;
}

.Choose-head img {
    float: right;
    width: 25%;
}

.Choose-head .trips-1 {
    color: #386B33;
    font-family: 'The Friday Stroke Regular';
    font-weight: 400;
    margin-left: 0;

}

.flare {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 450px; 
    margin-top: -20px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
    border-radius: 20px;

}
.flare video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.road {
    width: 100%;
}



#trip-grp{
  margin-top: 2%;
}

.Trip-name {
    padding-top: 10%;
    
}

.Trip-name h3 {
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    height: 60px;
    font-size: 24px;
}

.Trip-name p {
    width: 100%;
    font-size: 15px;
}



.temple-back {
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(175, 78, 58, 0.6), rgba(175, 78, 58, 0.4)), url(../img/Temple-img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* Set an appropriate height */
}

.center-text {
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    color: white;
    text-align: center;
    /* Center text horizontally */
}

.center-text h2 {
    font-size: 60px;
}

.center-text span {
    font-family: 'The Friday Stroke Regular';
    font-weight: 400;
    color: #FFD80D;
}

.center-text img {
    width: 20%;
    margin-left: 35%;
    margin-top: -25px;

}

.upcoming-head {
    padding-top: 1%;
    padding-left: 4%;
    padding-right: 4%;
    width: 43%;
    margin-left: auto;
    margin-right: auto;
}

.upcoming-head h2 {
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 40px;

}

.upcoming-head img {
    float: right;
}

.upcoming-head .trips-1 {
    color: #386B33;
    font-family: 'The Friday Stroke Regular';
    font-weight: 400;
}

.up-next {
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
    padding-bottom: 1%;
}

.district {
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 30px;
    padding-top: 3%;
}

.district img {
    height: 40px;
}

.tour-detail {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #318A2A1A;
    background-color: #318A2A1A;
    padding: 4%;
    font-family: 'Prompt', sans-serif;
    font-weight: 100;
}

.tour-detail p {
    text-align: left;
}

.right-section {
    background-color: #FFFFFF;
    margin: 1.5%;
    padding-bottom: 5%;
    width: 80%;
    border-radius: 10px;
}

.Tour-package {
    padding-top: 3%;
    padding-left: 3%;
    padding-top: 3%;
}

.main-sec {
    display: flex;
    border: 1px solid #bdbbbb;
    margin: 4%;
    border-radius: 10px;
}

#main-sec{
    background-image: url(/public/images/background.png);
}

.h {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    overflow-x: hidden;
    background-image: url(/public/images/list-page-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
}

.package-head {
    text-align: center;
    color: #086900; /* Set the text color to be visible on the background */
}

.Package-head {
    text-align: center;
    padding-left: 4%;
    padding-right: 4%;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}

.Package-head h2 {
    font-family: 'Prompt', sans-serif;
    font-weight: 400;
    font-size: 30px;
}

.Package-head img {
   margin-left: auto;
   margin-right: auto;
}

.thumbnail {
    width: 200px;
    height: 200px;
    border-radius: 5px;
}

.content-right{
    width: 70%;
    line-height: 20px;
    padding: 1%;
}

.content-right h2 {
    font-size: 20px;
}

.content-right p {
    font-size: 14px;
}

.txt-5{
    padding-top: 2%;
}
.destination{
    margin-top: 2px;
}

.shortDec{
    line-height: 16px;
}




.days {
    color: #386B33;
    font-weight: bold;
    padding-left: 5px;
}


.persons span {
    color: #386B33;
    font-weight: bold;
    font-size: 30px;
}

.Tour-package p {
    color: #386B33;
    font-weight: bold;
    font-size: 30px;
    white-space: nowrap;
}
.Tour-package span {
    color: #959595;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 5px;
}

.persons {
    color: #727272;
    font-size: 12px;
}

.Packages {
    color: #727272;
}

.detail-button {
    color: white;
    background-color: #386B33;
    border: 1px solid #386b33;
    width: 150px;
    padding: 0%;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
}

.wrapper {
    display: flex;
}

#circle {
    color: rgb(62, 59, 59);
    width: 5px;
    height: 5px;
}

#fst {
    height: auto;

    overflow-x: hidden;
    scrollbar-width: none;
    overflow-y: scroll;
    /* Enable vertical scrollbar */
}

table {
    border-collapse: collapse;
    width: 100%;
}

tr {
    border-bottom: 1px solid #ddd;

}

th,
td {
    padding: 15px;
}

td:hover {
    cursor: pointer;
    color: #386B33;
}

.left-section {
    width: 300px;
    height: 100%;
    margin-left: 20px;
    margin-top: 20px;
    background-color: #f0f3f3;
    text-align: left;
}

.hd-1 {
    text-align: center;
    height: 50px;
    background-color: rgb(226, 219, 219);
    padding-top: 10px;
    border: 1px solid #bdbbbb;
    color: #386B33;
    font-weight: 600;
    margin-bottom: 0rem;
}


#fst::-webkit-scrollbar {
    width: 5px;
}

/* Target the track (the area behind the scrollbar) */
#fst::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Target the thumb (the draggable part of the scrollbar) */
#fst::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

/* Target the thumb when the user is hovering over it */
#fst::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* review section  */

.Customer-review {
    padding: 5%;
    overflow-x: hidden;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url(../img/Review-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.customer-img {
    /* background-color: #FFD80D; */
    /* position: relative; */
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.review-img {
    position: relative;
    border-radius: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}
.CustImg{
    width: 100%;
    text-align: center;
    justify-content: center;
}

.review-img img {
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
    border: 2px dashed white;
    padding: 2%;
    width: 50%;
}

.review-img::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 3px;
    left: 25%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0%), rgba(0, 0, 0, 94%));
    width: 50%;
    border-radius: 50%;
    
}

.feed-back {
    color: white;
    padding-top: 5%;
    text-align: left;
    font-weight: 400;
}

.CustImg h6 {
    position: relative;
    /* top: 10%; */
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-family: 'The Friday Stroke Regular';
    font-size: 30px;
    color: white;
    margin-top:-50px;
}
.review-img p {
    color: #FFF;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* contact-section  */


.contact-section {
    padding: 5%;
    padding-top: 8%;
  padding-bottom: 8%;
}

.contact-section h2 {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.whatsapp-section {
    padding-top: 5%;
}

.whatsapp-section button {
    width: 200px;
    padding: 1%;
    background-color: #318A2A;
    color: white;
    border: none;
    border-radius: 10px;
}
.mail{
    display: flex;
}
/* Footer section */


.footer {
    /* padding: 5% 1% 0% 5%; */
    /* overflow-x: hidden; */
    background-image: url(../img/Footer-bg.png);
    /* background-position: center; */
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 5%;
    padding-bottom: 2%;
}
.link{
    text-decoration: none;
}
.footer-logo {
    text-align: center;
    
}
.CompanyLogo{
    text-align: center;
    width: 50%;
}

.footer-des {
    padding-top: 4%;
    width: 80%;
    font-size: 12px;
    text-align: left;
    color: #F8F8F8;
    line-height: 25px;
    font-weight: 100;

}


.quick-links {
    padding-top: 3%;
    color: #F8F8F8;
}

.quick-links h2 {
    position: relative;
}

.quick-links img {
    position: relative;
    z-index: 1;
}

.quick-links .background-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    font-family: 'The Friday Stroke Regular';
    color: #F8F8F8;
    font-size: 30px;
    padding-left: 7%;
}


.quick-links p {
    width: 100%;
    font-weight: 100;
    font-family: 'Prompt', sans-serif;
    color: #F8F8F8;
}
.quick-links .Arrow{
    padding-right: 20px;
}

.quick-links Link {
    color: white;
    text-decoration: none;
}


.Services {
    padding-top: 3%;

    color: #F8F8F8;
}

.Services h2 {
    position: relative;
}

.Services img {
    position: relative;
    z-index: 1;
}

.Services .background-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    font-family: 'The Friday Stroke Regular';
    color: #F8F8F8;
    font-size: 30px;
    padding-left: 7%;
}

.Services p {
    width: 100%;
    font-weight: 100;
    font-family: 'Prompt', sans-serif;
    color: #F8F8F8;
}


.address {
    padding-top: 3%;
    color: #F8F8F8;
}

.address h2 {
    position: relative;
}

.address img {
    position: relative;
    z-index: 1;
}

.address .background-text {
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 0;
    font-family: 'The Friday Stroke Regular';
    color: #F8F8F8;
    font-size: 30px;
    padding-left: 5%;
}

.address p {
    width: 100%;
    text-align: left;
    font-weight: 100;
    font-family: 'Prompt', sans-serif;
    color: #F8F8F8;
}

.address p a {
    color: #ffffff;
    text-decoration: none;
}

.terms-condition {
    padding-top: 5%;
    justify-content: center;
    text-align: center;
}

.copy-rights p {
    font-size: 12px;
    color: white;
    justify-content: center;
    text-align: center;
}

.policy {
    display: flex;
    gap: 70px;
    justify-content: center;
    text-align: center;
}

.policy p {
    color: white;
    font-size: 12px;
}

.bottom-button{
    /* display: flex;
    justify-content: space-between;
    width: 100%; */
   /* background-color: red;
    float: right; */
}

.bottom-button button{
    float: right;
}

.Name{
    float:right;
}
.tripImg{
    width: 80%;
}
.Company{
    text-decoration: none;
    color: #FFF;
}
.companyName{
    color: #FFF;
    font-family: 'Eras Demi ITC';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}