.banner {
    position: relative;
    height: 85vh;
    margin: auto;
}

.img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.banner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.69) 0%, rgba(0, 0, 0, 0.42) 100%);
}

.background {
    background-image: url('../img/background.png');
    overflow: hidden;
}

.priceContainer {
    position: relative;
    margin-top: -60px;
    background-color: rgb(255, 255, 255);
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    border: 9px;
    border-top-color: #386B33;
    border-top-style: solid;
    /* height: 150px; */
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
}

.Per {
    font-weight: 600;
    line-height: 0.3em;
    padding-left: 15px;
}

.btn {
    margin-top: 40px;
    background-color: #386B33;
    height: 45px;
}

.connect {
    text-align: right;
    margin-left: -10px;
}

.From {
    margin-top: 10px;
    line-height: 1em;
    padding-left: 15px;
    font-size: 18px;
}

.Price {
    color: #386B33;
    font-family: Prompt;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin-left: 10px;
}


.ShortDescription {
    margin-left: 15px;
    margin-right: 20px;
}

.price,
.from,
.per {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 19px;
}

.shortdesc {
    font-size: var(--font-size-sm);
    line-height: 20px;
    text-align: left;
}

.caption {
    position: absolute;
    top: 30%;
    vertical-align: middle;
    left: 0;
    width: 100%;
    color: white;
    padding: 10px;
    text-align: center;
}

/* .caption .TripImage{
    position: absolute;
    top: 30%;
    vertical-align: middle;
    height: 50px;
    width: 100px;
} */
.TripName {
    font-family: 'The Friday Stroke Regular';
    color: #FFF;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    padding-bottom: 20px;
}

.caption p {
    margin-top: 10px;
}

.date {
    font-size: var(--font-size-xl);
    line-height: 19px;
    font-weight: 500;
}

.Detail {
    background-color: #fff;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 30px;
    padding-bottom: 30px;
}

.VerticalLine {
    margin-top: 40px;
}

.VerticalLine .thick {
    width: 5px;
}

/* .Destination{
    display: flex;
    justify-content: space-between;
} */
.Img {
    padding-left: 40px;
    padding-top: 40px;
    height: 5%;
}

.Img1 {
    padding-top: 40px;
    height: 5%;
}

.Imgname {
    color: #386B33;
    text-align: center;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding-left: 10px;
    white-space: nowrap;
}

.para {
    padding-top: 5px;
    color: #1B1B1B;
    font-family: Prompt;
    font-size: 18;
    font-style: normal;
    font-weight: 500;
}

.desig {
    width: 25%;
    display: flex;
    justify-content: space-between;
}

.calender {
    width: 25%;
    display: flex;
    justify-content: space-between;
}

.transport {
    width: 20%;
    display: flex;
    justify-content: space-between;
}

.cancel {
    width: 30%;
}

.ItineryTitle {
    color: #151515;
    font-family: Prompt;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin-left: 2%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.ItineryDesc {
    color: #3C3C3C;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-left: 2%;
    margin-top: 3%;
}

.ExpandButton {
    border-style: none;
    background-color: transparent;
    /* margin-left: 15px; */
}

.details {
    overflow: hidden;
    transition: max-height 0.10s ease-out;
    margin-left: 15px;
}

.DayWise {
    margin-left: 4%;
    background-color: #386B33;
    padding: 6px;
    border-radius: 25px;
    color: #fff;
    padding-left: 20px;
    width: 50%;
    margin-top: -10px;
    z-index: 9999;
}

.DayWiseShortdescription {
    overflow: hidden;
}


ul {
    list-style-type: none;
    padding-bottom: 50px;
    margin-left: -20px;
    margin-top: -5px;
}

ul li {
    padding-top: 18px;
}

.expandable-section {
    position: relative;
}


.DaywiseItinery {
    margin-left: 40px;
    margin-top: 20px;
}
.Itinery{
    margin-top: 20px;
    display: flex;
    position: relative;
}

.itidownload{
    border-radius: 25px;
    background: #386b33;
    border: none;
    margin-left: 13%;
    padding: 6px;
    color: #FFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20%;
}
.itidownload1 {
    border-radius: 25px;
    background: #386b33;
    border: none;
    margin-left: 13%;
    padding: 6px;
    color: #FFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20%;
    display: none;

}

.Day {
    position: relative;
    border-left: 0.1em dashed #6C6C6C;
    margin-left: 30px;
    z-index: -0;
    margin-top: 10px;
    height: fit-content;
    margin-bottom: -6px;
}

.Day::before {
    content: "";
    border-top: 0.1em dashed #6C6C6C;
    position: absolute;
    top: 6px;
    left: 0;
    height: 1%;
    width: 10%;
}

.DDay {
    display: flex;
}

hr {
    height: 2px;
    width: 60%;
    border-width: 0;
    background-color: #888888;
    margin-left: 6%;
    margin-right: auto;
}

.ExpandButton {
    margin-left: -20px;
    z-index: 9999;
    margin-top: -10px;

}

.IntneryImage {
    padding: 10;
    border-radius: 10px;
    margin-left: 10px;
}

.IteneryImages {
    white-space: nowrap;
    overflow-x: auto;
}
.IteneryImages::-webkit-scrollbar {
    display: none;
}

.IteneryImages img {
    width: 153px;
    height: 122px;
    display: inline-block;
    margin-right: 20px;
}

.place {
    color: #386B33;
    font-family: Prompt;
    font-size: 21px;
    font-style: normal;
    font-weight: bolder;
    line-height: 19px;
    padding-top: 3px;
    margin-left: 10px;
}

.ViewonMap {
    border-radius: 16px;
    background: rgba(56, 107, 51, 0.22);
    border: none;
    width: 120px;
    height: 26px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Prompt;
    font-size: 12px;
    font-style: oblique;
    font-weight: 300;
    line-height: 19px;
    margin-left: 10px;
}

.Date {
    color: #386B33;
    font-family: Prompt;
    font-size: 16px;
    font-style: normal;
    font-weight: bolder;
    line-height: 19px;
    margin-right: 20px;
    padding-top: 3px;
    margin-left: 10px;
}

.Time {
    color: #386B33;
    font-family: Prompt;
    font-size: 16px;
    font-style: normal;
    font-weight: bolder;
    line-height: 19px;
    padding-top: 3px;
    margin-left: 10px;
}

.DateTimeDetails {
    display: flex;
    margin-top: 20px;
    margin-left: 15px;
    padding-right: 10px;
}

.Line {
    height: 40px;
    width: 1px;
    margin-left: 15px;
    margin-right: 15px;

}

.Part1 {
    display: flex;
    padding-top: 8px;
    margin-right: 15px;

}

.Part2 {
    display: flex;
    padding-top: 8px;
    margin-left: 15px;
}

.ViewonMapSvg {
    display: none;
}

.Tag7{
    font-size: 20px;
    color: black;
    padding-left: 1%;
}

@media (max-width: 1024px) {
    .Img {
        margin-left: -15px;
    }

    .VerticalLine {
        margin-top: 45px;
    }
    .priceContainer {
        background-color: rgb(255, 255, 255);
        width:60%;
    }
}

@media (max-width: 768px) {
    .banner {
        position: relative;
        margin: auto;
    }

    .img {
        object-fit: cover;
        height: 85vh;
    }

    .priceContainer {
        background-color: rgb(255, 255, 255);
        width: 90%;
    }

    .connect {
        margin-top: -10px;
    }

    .desig {
        width: 50%;
        padding-left: 25px;
    }

    .calender {
        width: 50%;
    }

    .transport {
        width: 50%;
    }

    .cancel {
        width: 50%;
    }

    .Img {
        padding-left: 25px;
        padding-top: 10px;
    }

    .VerticalLine {
        margin-top: 10px;
    }

    .Img1 {
        padding-left: 10px;
        padding-top: 10px;
    }

    .Img3 {
        padding-left: 25px;
    }

    .LineHide {
        display: none;
    }

    .DayWiseShortdescription {
        overflow: hidden;
    }

    .DayWise {
        white-space: nowrap;
        width: 90%;
    }

    .IteneryImages {
        overflow-x: scroll;

    }

    .TripName {
        font-size: 50px;
        line-height: 50px;
        padding-bottom: 20px;
    }

    .Tag7{
        font-size: 20px;
    }

   
    .DaywiseItinery {
        margin-top: 0;
    }
    
}

@media (max-width: 767px) {
    .banner {
        position: relative;
        margin: auto;
    }

    .img {
        object-fit: cover;
        height: 85vh;
    }

    .priceContainer {
        background-color: rgb(255, 255, 255);
        width: 100%;
    }

    .connect {
        margin-top: -100px;
    }

    .desig {
        width: 100%;
        margin-left: 5px;
    }

    .calender {
        width: 100%;
    }

    .transport {
        width: 100%;
    }

    .cancel {
        width: 100%;
    }

    .menu {
        width: 100%;
        justify-content: center;
    }

    .Img {
        padding-left: 10px;
        padding-top: 10px;

    }

    .Img1 {
        padding-left: 10px;
        padding-top: 10px;
    }

    .Img3 {
        padding-left: 10px;
    }

    .Hide {
        display: none;
    }

    .DayWise {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ul {
        margin-right: 10px;
        margin-left: -50px;
    }

    .Day {
        margin-left: 20px;
    }

    .IteneryImages {
        overflow-x: scroll;
        margin-left: -15px;
    }

    .IteneryImages::-webkit-scrollbar {
        display: none;
    }

    .DateTimeDetails {
        display: inline;
        height: 10%;
        justify-content: space-between;
    }

    .Line {
        display: none;

    }

    .Part1 {
        margin-left: -10px;
        margin-top: -10px;
        /* display: inline; */
        /* height: 100px; */
    }

    .ViewonMap {
        display: none;
    }

    .ViewonMapSvg {
        text-align: right;
        display: block;
        width: 20%;
        border: none;
        background-color: transparent;
    }

    .Part2 {
        margin-left: -10px;
    margin-bottom: -20px;
    }

    .place {
        width: 100%;
        font-size: 15px;
    }
    
    .itidownload {
        display: none;
    }
    .itidownload1 {
        width: 60%;
        font-size: 16px;
        text-align: center;
        right: 0;
        display: inline;
        border: 1px ;
    }

    .ItineryDesc {
      margin-top: 6%;
}
    
    .price{
    }

    .Tag7{
        font-size: 16px;
        display: block;
        padding-top: 3%;
    }

    .DaywiseItinery {
        margin-top: 0;
    }
    
}
