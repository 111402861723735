.Quotes{
    padding-top: 10%;
    }
    .Quotes h1{
        color: #FFF;
        font-family: 'The Friday Stroke Regular';
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }
    .Quotes .Ques{
        text-align: center;
        color: #FFF;
        font-size: 15px;
        padding-top: 2%;
    }
    .Quotes .Para {
        text-align: center;
        color: #FFF;
        font-size: 15px;
        margin-top: -1%;
        width: 60%;
        margin-left:auto;
        margin-right: auto;
        margin-bottom: 6%;
    }
    .Quotes p {
        color: black;
        font-size: 18px;
    }
    .Quotes .Contact1{
        text-align: center;
    }
    .whatsapp-section2 button{
        width: 200px;
        background-color: #318a2a;
        border-radius: 10px;
        border: none;
        padding: 1%;
        font-weight: 500;
        color: #FFF;
    }
    .temple-back{
        height: 530px;
        padding-top: 10%;
    }
    @media (max-width: 767px) {
        .temple-back{
            height: 400px;
        }
        .Quotes h1{
            font-size: 25px;
        }
            .Quotes .Para {
                color: #FFF;
                font-size: 14px;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 5%;
            }
            .Quotes p {
                color: black;
                font-size: 14px;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
            }
            .CompanyLogo {
                text-align: center;
                width: 50%;
            }
    }
    