.district{
    /* display: flex; */
    color: #000;
    font-family: Prompt;
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    justify-content: center;
    padding-top: 5%;
}
.district-para{
    font-size: 25px;
    font-weight: 500;
    justify-content: center;
}
.Line{
    width: 100%;
    color: black;
    background-color: black;
}
.image-container1 {
    text-align: center;
    /* position: absolute; */
    margin-top: 5%;
}



.image-container1::-webkit-scrollbar {
    display: none;
}

.image-container1 {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.image-row1 {
    display: flex;
    justify-content: space-between;
    /* margin: 10px 0; */
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.image-row2 {
    display: flex;
    justify-content: space-between;
    /* margin: 10px 0; */
    width: 68%;
    margin-left: auto;
    margin-right: auto;
}

/* .image-wrapper {
    flex: 1;
    text-align: center;
} */



.horizontal-line {
    width: 100%;
    height: 10px;
    margin: 20px 0; 
    
}
.text-row {
    display: flex;
    justify-content: space-between;
    /* margin: 20px 0; */
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.image-wrapper1 {
    position: relative;
    display: inline-block;
}
.image-wrapper2 {
    position: relative;
    display: inline-block;
}
.image-wrapper2{
    margin-top: -20px;
}
.odd-index{
    transform: rotate(180deg);
}


.overlay-image {
    height:55px;
    width: 45px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.even-index1 {
    transform: translate(-50%, -55%);
}
.odd-index1 {
    transform: translate(-50%, -55%) rotate(180deg);
}

.odd{
    display: none;
}
.even{
    display: none;
}

.odd_text{
display: none;
}
/* .even_text {
    margin-top: -30px;
} */
.container1 {
    display: flex;
    align-items: center;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-left: 40px;
    margin-right:40px;
}

.container1 img {
    margin: 0;
}

.text-wrapper1{
    /* background-color: blue; */
    margin-top: 20px;
}


.Location1 {
    /* margin-left: -50px; */
}

.Location2 {
    margin-right:-50px;
}

.container2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}
.date1{
    font-size: 13px;
    line-height: 1px;
}
.upcoming-section {
    background-image: url('../img/Next-trips-bg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 700px;
    position: relative;
    
}
.Contact{
    padding-top: 7%;
}
.whatsapp-section1 button {
    width: 200px;
    padding: 1%;
    background-color: #318A2A;
    color: white;
    border: none;
    border-radius: 10px;
}


@media (max-width: 768px) {
    .district {
        font-size: 30px;
        justify-content: center;
        width: 100%;
    }

    .district-para {
        font-size: 20px;
        font-weight: 500;
        justify-content: center;
    }


    .image-container1 {
        width: 100%;
        overflow-x: auto;
    }

    
    .image-wrapper{
        width:100px;
        text-align: center;
    }
    
   
    .text-row {
        width: max-content;
    }

    .text-wrapper{
        width: 100px;
        white-space: nowrap;
        /* text-align: left; */
        /* padding-left: 15px; */
    } 
    .text-wrapper1 {
        width: 100px;
        white-space: nowrap;
        /* padding-left: 25px; */
    }
    .horizontal-line {
        display: none;
    }
    .overlay-image{
        margin-left: 0px;
    }
    .container1{
        display: none;
    }
    .container2{
        display: none;
    
    }

    .image-row1 {
        width: 100%;
        margin-top: 10px;
        text-align: center;
        padding-left: 16px;
    }
    .image-row2{
        margin-top: 20px;
        width: 100%;
    }
    .upcoming-section {
        height: 500px;
    }
        .image-container1 { 
            top: 50%;
        }
    
}

@media screen and (max-width: 480px) {

    .upcoming-section {
        padding-top: 18%;
        height: 500px;
    }

    .whatsapp-section1 button {
        width: 200px;
       height: 50px;
    }
}