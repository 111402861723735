.head-section{
    font-family: 'Prompt', sans-serif;

}


.terms-section{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    overflow-x: hidden;
    background-image: url(/public/images/list-page-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;

}

.terms-section h2{
    font-family: 'The Friday Stroke Regular';

    font-weight: 400;
    font-size: 60px;
    padding-top: 5%;
}

.terms{
    font-family: 'Prompt', sans-serif;
    padding: 1% 5% 0% 5%;
    text-align: left;
}

.sub-title{
    color: #8f9290;
}



@media screen and (max-width:480px) {

    .terms-section{
        padding: 10%;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        background-color: #F0CB86;
    
    }
    
    .terms-section h2{
        font-family: 'The Friday Stroke Regular';
    
        font-weight: 400;
        font-size: 35px;
        padding-top: 5%;
    }

}