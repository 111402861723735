@import url('https://fonts.googleapis.com/css2?family=Halant&family=Prompt&display=swap'); 

.next-trips-section {
    background-image: url('../../../public/images/upcoming-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    overflow-x: hidden;
}

.box-layout {
    min-width: 20%;
    height: 17%;
    cursor: pointer;
    /* background-color: aqua; */
    /* padding: 0% 3%; */
    /* margin: 0% 1%; */
    
}

.next-trips-amt {
    /* padding: 1%; */
    font-size: 20px;
}

.next-trips-thumbnail {
    width:100%;
    height: 250px;

}

.contact-button {
    /* width: 18%; */
    padding: 0.7%;
    font-size: 18px;
    /* box-shadow: 0px 10px 5px 0px; */
}

.badge-bg {
    width: 30%;
    /* height: 40px; */
    /* width: 60px; */
    z-index: 10;
    /* background-color: bisque; */
}

.badge-bg-amt {
    /* margin-left: 5%; */
    /* background-color: blueviolet; */
    color:#0B4507;
    background-color: #0B4507;
}

.vehicle-img {
    z-index: 30;
    max-height: 18px;
    justify-content: center;
    align-items: center;
    margin-right: 10%;
    margin-top: 2%;
    /* background-color: antiquewhite; */
}

.tour-vector {
    margin-top: -13px;
    margin-left: -15px;
    font-size: 22px;
    /* width: 110%; */
}
.tour-name {
    font-size: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
}

.next-trips-duration {
    color: #0B4507;
    font-weight: 600;
    font-size: 20px;
    align-content: center;
}


.primary-bg-color {
    background-color: #318a2a;
}

.secondary-font {
    font-family: 'The Friday Stroke Regular';
}

.z-index-2 {
    z-index: 20;
}

.next-trips-title {
    margin-top: 20px;
}
.MidText {
    font-family: 'The Friday Stroke Regular';
    font-size: 25px;
}

.Popular-head-1 {
    /* padding: 4%; */
    display: flex;
    text-align: center;
    width: 15%;
    margin-left: auto;
    margin-right: auto;
}

.Popular-head-1 h2 {
    font-family: "Prompt", sans-serif;
    font-weight: 600;
    font-size: 40px;
}

.Popular-head-1 img {
    /* float: right; */
    width: 120%;
    padding-bottom: 5%;
    margin-left: 15%;
}

.Popular-head-1 .trips-1 {
    color: #386b33;
    font-family: "The Friday Stroke Regular";
    font-weight: 400;
}

.our-gallery-section button {
    margin: 1%;
    border: none;
    height: 50px;
    background-color: inherit;
    
}

.our-gallery-section {
    background-image: url('../img/Brush.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat; 
    /* display: hidden; */
    /* background-color: aqua; */
    /* text-align: center; */
    padding: 6%;
}

.gradient-bg {
    /* background-image: url('../images/Gradient-bg.png'); */
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat; 
    /* background-color:#f1f1f1; */
    height: 50px;
}

.img-container1 {
    position: relative;
    /* width: 100%; */
}

.img-fluid1 {
    display: block;
    width: 100%;
    
    height: 150px;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .2s ease;
}

.img-container1:hover .overlay {
    height: 100%;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    white-space: nowrap;
    justify-content: center;
 
}

.text1 {
    color: white;
    font-size: 22px;
    top: 70%;
    left: 50%;
    font-weight: 600;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    z-index: 9999;
}

.Explore {
    height: 5%;
    width: 100%;
}

.ExploreImg {  
    top: 60%;
    text-align: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* background-color: black; */
}

.filter-bar{
    margin-top: -20px;
}
.our-gallery-images{
    margin-top: 10px;
    cursor: pointer;
}

.gallery-container{
    display: flex;
    justify-content: space-between;
}

#model {
    display: none;
    border: 1px solid black;
    padding: 2%; 
    /* background-image: url('/public/images/background-1.png'); */
    background-color: #ededed;
    overflow-y:hidden;
    width: 90%;
    height: 96%;
    border-radius: 10px;
    z-index: 9999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.close{
    cursor: pointer;
}

.buttons{
    display: none;
}


.gallery-images-1 {
    width: 12%; 
    display: flex;
    flex-direction: column;
    overflow-x: auto; 
    gap: 4%;
    height: 500px;
}

.gallery-images-1 {
    overflow-x: hidden;
    overflow-y: auto;
    white-space: nowrap;
  }
  
  .gallery-images-1::-webkit-scrollbar {
    display: none; 
  }
  

.gallery-images-1 .rounded-start{  
    width: 100px;
    height: 80px;
    object-fit: cover;
    display: inline-block;
    border-radius: 10px;
    margin: 2%;
    cursor: pointer;

}


.selectedimg{
    width:120%;
    display: flex;
    justify-content: left;
   
    margin-bottom: 2%;
    height: 510px;

}

.selectedimg img{
    width: 100%;
    height: 100%;
    border-radius: 10px;

}

.blur-background {
    filter: blur(5px);
  }
  
  .selected-image-border {
    border: 4px solid #53834f;

  }
   
.gallry-view{
    margin-top: 3%;
    background-color: green;
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.gallry-view button{
    color: white;

}
@media screen and (max-width:480px) {
    .gallry-view{
        width: 35%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
    }
    .gallery-container{
        display: block;
    }
    .gallery-images-1 {
        width: 100%; 
        display: flex;
        flex-direction: row;
        overflow-x: auto; 
        gap: 4%;

    }
    .selectedimg{
        width:95%;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        height: 300px;
        margin-top: 10%;
    }

    .selectedimg img{
        object-fit: contain;
    }

    #model{
        width: 90%;
       margin: 1%;
       height: 80%;
       padding: 2%;
    }
    .buttons{
        display: flex;
    }

    .our-gallery-section {
        width: 100%;
        overflow: hidden;
        padding: 0;
    }

    .gallery-images-1 .rounded-start{  
    margin: 0%;
    
    }
}

@media screen and (min-width:481px) and (max-width:768px) {
    .gallry-view{
        width: 25%;
        margin-left: auto;
        margin-right: auto;
    }
    }
@media screen and (min-width:1540px) {
    #model{
        height:70%;
    }

}