.reviewSection{
    text-align: center;
    padding-top: 5%;
  padding-bottom: 5%;
}
.review{
    color: #FFF;
    font-family: Prompt;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;
}
.img-review{
    position: relative;
}
.img-review img {
    border: 2px dashed #FFF;
    border-radius: 50%;
    width: 50%;
    padding: 5px;
}
.img-review::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 3px;
    left: 27%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0%), rgba(0, 0, 0, 94%));
    width: 46%;
    border-radius: 50%;
}

.img-review h6{
    color: #FFF;
    position: absolute;
    bottom: 5%;
    left: 50%; 
    transform:translate(-50%, -50%);
    text-align:center; 
    margin:0;
    font-family: 'The Friday Stroke Regular';
    font-size: 30px;
    font-weight: 400;
}
.personName{
    white-space: nowrap;
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    /* .img-review::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        bottom: 0px;
        left: 25%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0%), rgba(0, 0, 0, 94%));
        width: 50%;
        border-radius: 50%;
    } */
}

@media screen and (max-width:480px) {

    .img-review img {
       
        width: 50%;
    }
    
} 