

@media screen and (max-width: 1460px) {


    /* .footer {
        padding-top: 10%;
    } */

    .footer-des {

        font-size: 14px;


    }


    .feed-back {
        width: auto;
    }


  
}

@media screen and (min-width:1540px) {

   

    .upcoming-head {
        padding-top: 1%;
        padding-left: 4%;
        padding-right: 4%;
        width: 34%;
        margin-left: auto;
        margin-right: auto;
    }

    .upcoming-head img {
        float: right;
        width: 35%;
    }

    .Choose-head img {
        float: right;
        width: 25%;
    }

    .Choose-head {
        padding: 4%;
        width: 35%;
        margin-left: auto;
        margin-right: auto;
    }

    .right-section {
        margin: 3%;
        width: 100%;
    }

    .left-section {
        margin-top: 3%;
        margin-left: 3%;
        width: 30%;

    }

    .feed-back {
        width: auto;
    }


    /* .footer {
        padding-top: 10%;
    } */
}


@media screen and (min-width:768px) and (max-width:1024px) {
    .our-gallery-section {
        padding: 0%;
    }
    .img-fluid1{
         object-fit: cover;
         padding: 0;
    }
  
  
    .filter-bar button{
        font-size: 12px;
    }
    .next {
        width: 100%;
        
     }
     .next-trip{
        width: 200px;
        padding: 5%;
    }

    
     .next-trip .main-img{
        width: 20%;
        height: 20%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
        cursor: pointer;
    
    
    }
    .Popular-head {
        padding: 2%;
        margin-left: auto;
        margin-right: auto;
        width: 40%;

    }

    .Popular-head h2 {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 30px;

    }

    .Popular-head img {
        float: right;
        width: 4 0%;
    }

    .Popular-head .trips-1 {
        color: #386B33;
        font-family: 'The Friday Stroke Regular';
        font-weight: 400;
    }


    .Choose-head {
        padding: 4%;
        width: 55%;
        margin-left: auto;
        margin-right: auto;
    }

    .Choose-head h2 {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 40px;
    }

    .Choose-head img {
        float: right;
        width: 25%;
    }

    .Choose-head .trips-1 {
        color: #386B33;
        font-family: 'The Friday Stroke Regular';
        font-weight: 400;
        margin-left: 0;

    }

    .tripImg {
        width: 50%;

    }

    .Trip-name {
        padding-top: 10%;
    }

    .Trip-name h3 {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 20px;
    }

    .Trip-name p {
        width: 100%;
        font-size: 14px;
    }

    .temple-back {
        width: 100%;
        height: 300px;
    }



    .temple-back .center-text {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        color: white;
        margin: 0;
        position: relative;
        top: 25%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .center-text h2 {
        font-size: 30px;

    }

    .center-text span {
        font-family: 'The Friday Stroke Regular';
        font-weight: 400;
        color: #FFD80D;
    }

    .center-text img {
        width: 25%;
        margin-left: 40%;
        margin-top: -8%;
    }

    .upcoming-head {
        width: 33%;
    }

    .upcoming-head h2 {
        font-size: 20px;
    }

    .upcoming-head img {
        float: right;
        width: 30%;
    }

    .customer-img img {

        width: 50%;
    }

    .feed-back {

        font-size: 14px;
    }

    .review-img {
        position: relative;
        border: 3px dashed white;
        border-radius: 50%;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* Center-align vertically */
        margin-left: auto;
        margin-right: auto;
    }

    .review-img img {
        border-radius: 50%;
        padding: 2%;
        width: 100%;
    }

    .customer-img {
        position: relative;
    }

    .customer-img h6 {
        position: absolute;
        top: 40%;

        font-size: 20px;
        color: white;
    }

    /* footer section */

    .footer-logo {
        text-align: left;
        width: 50%;
    }

    .footer-main {
        width: 35%;
        padding: 0;
    }



    .Services {
        padding: 0% 0% 0% 0%;

        padding-top: 3%;

        color: #F8F8F8;
    }



    .address {
        padding: 0% 0% 0% 0%;
        padding-top: 3%;
        color: #F8F8F8;
    }




    .footer-des {
        width: 80%;
        font-size: 12px;
        line-height: 20px;
    }


    .quick-links {
        padding-top: 3%;
        color: #F8F8F8;
    }

    .quick-links h2 {
        position: relative;
        font-size: 10px;
    }

    .quick-links img {
        position: relative;
        z-index: 1;
    }

    .quick-links .background-text {
        position: absolute;
        top: 7px;

        font-size: 20px;
        padding-left: 15%;
    }


    .quick-links p {

        font-size: 12px;
    }


    .Services {
        padding-top: 3%;

        color: #F8F8F8;
    }

    .Services h2 {
        position: relative;
    }

    .Services img {
        position: relative;
        z-index: 1;
    }

    .Services .background-text {
        position: absolute;
        top: 7px;
        font-size: 20px;
        padding-left: 15%;
    }

    .Services p {
        width: 100%;
        font-size: 12px;
        font-weight: 100;
        font-family: 'Prompt', sans-serif;
        color: #F8F8F8;
    }


    .address {
        padding-top: 3%;
        color: #F8F8F8;
    }

    .address h2 {
        position: relative;
    }

    .address img {
        position: relative;
        z-index: 1;
    }

    .address .background-text {
        position: absolute;
        top: 7px;

        font-size: 20px;
        padding-left: 15%;
    }

    .address p {
        width: 100%;
        text-align: left;
        font-weight: 100;
        font-family: 'Prompt', sans-serif;
        color: #F8F8F8;
        font-size: 12px;
    }

    .terms-condition {
        padding-top: 5%;
        float: right;
    }


    .policy {
        display: flex;
        gap: 70px;
    }

    .policy p {
        color: rgb(255, 255, 255);
        font-size: 10px;
    }

        
.short{
    line-height: 23px;
}

.destination{
    line-height: 23px;
}
.persons span {
    color: #386B33;
    font-weight: bold;
    font-size: 20px;
    width: 200px;
}

.persons {
    color: #727272;
    font-size: 12px;
    width: 200px;
}

.bottom-button {
width: 100%;
margin-bottom: 4%;
}

.bottom-button button{
    float: left;
}




}


@media screen and (min-width:481px) and (max-width:767px) {
    .our-gallery-section {
        padding: 0%;
    }
    .img-fluid1{
        object-fit: cover;
        padding: 0;
   }
    .Choose-head {
        padding: 4%;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .tripImg{
        width: 50%;
    }

    .Choose-head h2 {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 40px;
    }

    .Choose-head img {
        float: right;
        width: 25%;
    }

    .Choose-head .trips-1 {
        color: #386B33;
        font-family: 'The Friday Stroke Regular';
        font-weight: 400;
        margin-left: 0;

    
    }

    .short{
        line-height:19px;
    }
    
    .destination{
        line-height: 23px;
    }

    

}


@media screen and (max-width:480px) {

    .our-gallery-section {
        padding: 0%;
    }
    .img-fluid1{
        padding: 4%;
        min-height: 200px;
   }
    .hello {
        background-image: url(../img/why-choose-mbl-bg.png);
    }

    .Popular-head {
        padding: 2%;
        margin-left: auto;
        margin-right: auto;
        width: 90%;

    }

    .Popular-head h2 {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 30px;

    }

    .Popular-head img {
        float: right;
        width: 35%;
    }

    .Popular-head .trips-1 {
        color: #386B33;
        font-family: 'The Friday Stroke Regular';
        font-weight: 400;
    }


    .Choose-head {
        padding: 4%;
        width: 65%;
        margin-left: auto;
        margin-right: auto;
    }

    .Choose-head h2 {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 20px;
    }

    .Choose-head img {
        float: right;
        width: 25%;
    }

    .Choose-head .trips-1 {
        color: #386B33;
        font-family: 'The Friday Stroke Regular';
        font-weight: 400;
        margin-left: 0;

    }

    #trip-grp {
        display: 100%;
    }

    .tripImg {
        width: 50%;
    }

    .temple-back {
        width: 100%;
        height: 200px;
    }



    .temple-back .center-text {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        color: white;
        margin: 0;
        position: relative;
        top: 25%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .center-text h2 {
        font-size: 20px;

    }

    .center-text span {
        font-family: 'The Friday Stroke Regular';
        font-weight: 400;
        color: #FFD80D;
    }

    .center-text img {
        width: 25%;
        margin-left: 40%;
        margin-top: -8%;
    }

    .upcoming-head {
        width: 63%;
    }

    .upcoming-head h2 {
        font-size: 20px;
    }

    .upcoming-head img {
        float: right;
        width: 30%;
    }

    .district {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 20px;
        padding-top: 3%;
    }

    .district img {
        height: 30px;
    }

    .district-para {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
    }


    .road {
        width: 100%;
        overflow: auto;
        /* or overflow: scroll; */

    }

    .road::-webkit-scrollbar {
        display: none;
    }

    

    .right-section {
        margin: 3%;
        width: 95%;
    }

    .tour-detail {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 4%;
        font-family: 'Prompt', sans-serif;
        font-weight: 100;
    }

    .tour-detail p {
        text-align: left;
    }

    .main-sec {
        display: block;
        border: 1px solid #bdbbbb;
        margin: 4%;
        border-radius: 10px;
        height: auto;
    }

    

    /* .h img {
        height: 100%;
        object-fit: cover;
    } */

    .Tour-package {
        width: 80%;

    }

    .thumbnail {
        width: 100%;
        height: 240px;
        border-radius: 5px;
    }

    

    .content-right{
        padding-left:3%;
    }

    .content-right h2 {
        font-size: 20px;
        width: 200px;
    }

    .content-right p {
        font-size: 14px;
        width: 100%;
        line-height: 20px;

    }

    
.short{
    line-height: 18px;
}

.destination{
    line-height: 15px;
}

    .days {
        color: #386B33;
        font-weight: bold;
    }

    .price {
        color: #386B33;
        font-weight: bold;
        font-size: 30px;
    }

    .persons {
        color: #727272;
        font-size: 12px;
    }

    .wrapper {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .left-section {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .review-img {
        width: 80%;
    }

    .customer-img {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
    }

    .customer-img h6 {
        position: absolute;
        top: 45%;

        font-size: 20px;
        color: white;
    }

    .customer-img img {
        width: 100%;
    }

    .feed-back {
        width: auto;
    }


    .contact-section {
        padding: 5%;
    }

    .contact-section h2 {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
    }


    /* footer section */


    .footer {
        padding: 10%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-des {
        padding-top: 4%;
        width: 100%;
        font-size: 14px;
    }

    .quick-links {
        padding-top: 10%;
    }

    .Services {
        padding-top: 3%;
        width: 100%;
        color: #F8F8F8;
    }

    .address p {
        width: 100%;
        text-align: left;
        font-weight: 100;
        font-size: 14px;
        font-family: 'Prompt', sans-serif;
        color: #F8F8F8;
    }


    .policy {
        padding-top: 5%;
        display: block;
        text-align: center;

    }

    .copy-rights p {
        font-size: 12px;
        color: white;
    }

    .h {
     
        padding: 5%;
        overflow-x: hidden;
        background-image: url(/public/images/list-page-bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        width: 100%;
    }
    
  
    /* .bottom-button{
      display: block;
      width: 100%;        
    }

    .bottom-button button{
        margin-top: 5%;
        float: left;
    }
     */

     .bottom-button button{
        float: left;
        margin-bottom: 5%;
    }
   
   
    .footer-logo {
        text-align: left;
    }

    .next{
        width: 100%;
        overflow: hidden;
    }

    .next-trip{
      margin-left: auto;
      margin-right: auto;
    
    }

}
@media screen and (max-width:768px) {
    .content-right
    {
        width: 100%;
    }
    .content-right .shortDec {
            font-size: 14px;
            width: 100%;
            line-height: 20px;
        }
    .bottom-button button {
        margin-top: 5%;
    }
    .txt-5{
        font-size: 12px;
    }

    .destination img{
        width: 12px;

    }

    .txt-5 img{
      width: 10px;
    }
        
}