.RelatedTour {
    margin-top: 60px;
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}

.RelatedTour .Title {
    color: #3D3D3D;
    font-family: Prompt;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.TourImages {
    margin-top:3%;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.TourImages .ImageTitle {
    color: #3D3D3D;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding-top: 10px;
    padding-left: 10px;
}

.TourImages .rating {
    padding-left: 10px;
    margin-top: -10px;
}

.image-container {
    position: relative;
}

.Images {
    width: 100%;
    height: auto;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFF;
    font-size: 20px;
    font-family: The Friday Stroke;
}

.sliding-line {
    width: 100%;
    height: 2px;
    background-color: #000;
    overflow: hidden;
}

.sliding-line p {
    margin: 0;
    padding: 0;
    display: inline-block;
    animation: slideIn 1s ease-in-out;
}

.img-container {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .2s ease;
}

.img-container:hover .overlay {
    height: 100%;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    /* left: 50%; */
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    white-space: nowrap;
}

.text1 {
    color: white;
    font-size: 22px;
    font-weight: 400;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    z-index: 9999;
    margin-top: -10px;
    font-family: 'The Friday Stroke Regular';

}

.Explore {
    height: 5%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.ExploreImg {
    text-align: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    margin-top: -5px;
}

.MidText {
    font-family: 'The Friday Stroke Regular';
    font-size: 25px;
}

.star {
    position: relative;
    color: #FFF;
}

.star::before,
.star::after {
    content: '\2605';
    position: absolute;
    top: 0;
    left: 0;
    color: #386B33;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@media (max-width: 767px) {
    /* .TourImages {
        display: inline;
        width: 100%;
        } */
}

@media (max-width: 768px) {

    /* .TourImages {
        display: inline;
        width: 100%;
    } */
    .image-container {
        justify-content: center;
        text-align: center;
    }

    .img-container{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .image{
       
    }

}