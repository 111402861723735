@media only screen and (min-width:1900px){
    .Popular-head-1 {
        font-size: 60px !important;
    }
    .next-trips-section {
        font-size: 40px !important;
    }
    .next-trips-thumbnail {
        height: 450px !important;
    }

    .box-layout {
        margin-right: 1.5% !important;
        margin-left: 1.5% !important;
        width: 20px !important;
        padding: 0% 3%;
    }

    .badge-bg{
     width: 20% !important;
    }

    .next-trips-amt {
        font-size: 45px !important;
    }

    .tour-name {
        font-size: 40px !important;
    }

    .next-trips-duration {
        margin-top: 10% !important;
        font-size: 40px !important;
        /* font-family: 'The Friday Stroke'; */
    }

    .tour-vector {
        margin-top: -20px !important;
    }

    .contact-button {
        width:20% !important;
        height: 80px;
    }
}



@media screen and (max-width: 1460px) {


    /* .footer{
        padding-top: 10%;
    } */

    .footer-des{
     
        font-size: 16px;
       
    
    }

    
    .feed-back {
        width: auto;
     }
     
    /* .next-trips-thumbnail {
        height: 280px !important;
    } */
   
     
}

@media only screen and (min-width:1540px){

    .upcoming-head{
        padding-top: 1%;
        padding-left: 4%;
        padding-right: 4%;    
        width: 34%;
        margin-left: auto;
        margin-right: auto;
    }
    .upcoming-head img{
        float: right;
        width: 35%;
     }

     .Choose-head img{
        float: right;
        width: 25%;
    }

    .Choose-head{
        padding: 4%;
        width: 35%;
        margin-left: auto;
        margin-right: auto;
    }

    .right-section{
        margin: 3%;
        width: 100%;
    }

    .left-section{
        margin-top: 3%;
        margin-left: 3%;
        width: 30%;

     }

     .feed-back {
       width: auto;
    }
    

     /* .footer{
        padding-top: 10%;
    } */

    .next-trips-amt {
        margin-top: 0.5% !important;
        font-size: 24px !important;
    }

    .vehicle-img {
        margin-top: 3% !important;
        /* padding-bottom: 2% !important; */
        max-height: 24px !important;
    }

    .contact-button {
        /* padding: 2% !important; */
    }

    .next-trips-thumbnail {
        height: 320px !important;
    }

    .next-trips-title {
        padding: 0px !important;
    }

     .box-layout {
        /* margin-left: 22%; */
        /* display: none; */
        /* margin-right:20%; */
        /* background-color: aqua; */
        margin-left: 1% !important;
        margin-right: 1% !important;
    }
}



@media screen and (min-width:768px) and (max-width:1024px) {

    .our-gallery-section {
        padding: 0%;
    }

    .flare{
        width:100%;
        padding: 5%;
        height: 350px;
    }
    .Popular-head{
        padding: 2%;
        margin-left: auto;
        margin-right: auto;
        width: 40%;

    }
    
    .Popular-head h2{
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 30px;
    
    }
    
    .Popular-head img{
        float: right;
        width: 4  0%;
    }
    
    .Popular-head .trips-1{
        color: #386B33;
        font-family: 'The Friday Stroke Regular';
         font-weight: 400;
     } 

     
 .Choose-head{
    padding: 4%;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
}

.Choose-head h2{
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 40px;
}

.Choose-head img{
    float: right;
    width: 25%;
}

.Choose-head .trips-1{
    color: #386B33;
    font-family: 'The Friday Stroke Regular';
    font-weight: 400;
    margin-left: 0;
    
 }

 .tripImg{
    width: 50%;

 }

 .Trip-name{
    padding-top: 10%;
}

.Trip-name h3{
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 20px;
}

.Trip-name p{
    width: 100%;
    font-size: 14px;
}

.temple-back{
    width: 100%;
    height: 300px;
 }
 
 
 
 .temple-back .center-text{
     font-family: 'Prompt', sans-serif;
     font-weight: 600;
     color: white;
     margin: 0;
     position: relative;
     top: 25%;
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
 }
 
 .center-text h2{
     font-size: 30px;
 
 }
 
 .center-text span{
     font-family: 'The Friday Stroke Regular';
     font-weight: 400;
     color: #FFD80D;
 }
 
 .center-text img{
     width: 25%;
     margin-left: 40%;
     margin-top: -8%;
 }

 .upcoming-head{
    width: 33%;
}

.upcoming-head h2{
    font-size: 20px;
}

.upcoming-head img{
   float: right;
   width: 30%;
}

.customer-img img {
    
    width: 50%;
}

.feed-back{
  
    font-size: 14px;
}

.review-img {
    position: relative;
    border: 3px dashed white;
    border-radius: 50%;
    width: 60%;
    display: flex; 
    justify-content: center;
    align-items: center; /* Center-align vertically */
    margin-left: auto;
    margin-right: auto;
}

.review-img img {
    border-radius: 50%;
    padding: 2%;
    width: 100%;
}

.customer-img {
    position: relative;
}

.customer-img h6 {
    position: absolute;
    top: 40%; 
  
    font-size: 20px;
    color: white;
}
 
/* footer section */

.footer-logo{
    text-align: left;
    width: 50%;
}

.footer-main{
    width: 35%;
    padding: 0;
}



.Services{
    padding: 0% 0% 0% 0%;

    padding-top: 3%;

    color: #F8F8F8;
}



.address{
    padding: 0% 0% 0% 0%;

    padding-top: 3%;
    color: #F8F8F8;
}




.footer-des{
    width: 80%;
    font-size: 12px;
    line-height: 20px;
}


.quick-links{
    padding-top: 3%;
    color: #F8F8F8;
}

.quick-links h2 {
    position: relative;
    font-size: 10px;
}

.quick-links img {
    position: relative;
    z-index: 1;
}

.quick-links .background-text {
    position: absolute;
    top: 7px;
   
    font-size: 20px;
    padding-left: 15%;
}


.quick-links p{
   
    font-size: 12px;
}


.Services{
    padding-top: 3%;

    color: #F8F8F8;
}

.Services h2 {
    position: relative;
}

.Services img {
    position: relative;
    z-index: 1;
}

.Services .background-text{
    position: absolute;
    top: 7px;
    font-size: 20px;
    padding-left: 15%;
}

.Services p{
    width: 100%;
    font-size: 12px;
    font-weight: 100;
    font-family: 'Prompt', sans-serif;
    color: #F8F8F8;
}


.address{
    padding-top: 3%;
    color: #F8F8F8;
}

.address h2 {
    position: relative;
}

.address img {
    position: relative;
    z-index: 1;
}

.address .background-text {
    position: absolute;
    top: 7px;
  
    font-size: 20px;
    padding-left: 15%;
}

.address p{
    width: 200px;
    text-align: left;
    font-weight: 100;
    font-family: 'Prompt', sans-serif;
    color: #F8F8F8;
    font-size: 12px;
}

.terms-condition{
    padding-top: 5%;
    float: right;
}


.policy{
    display: flex;
    gap: 70px;
}

.policy p{
   color: rgb(255, 255, 255);
   font-size: 10px;
}

}

@media screen and (max-width:480px) {

 
    .hello{
        background-image: url(../img/why-choose-mbl-bg.png);
    }
    .Popular-head{
        padding: 2%;
        margin-left: auto;
        margin-right: auto;
        width: 50%;

    }
    
    .Popular-head h2{
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 20px;
    
    }
    
    .Popular-head img{
        float: right;
        width: 35%;
    }
    
    .Popular-head .trips-1{
        color: #386B33;
        font-family: 'The Friday Stroke Regular';
         font-weight: 400;
     } 

   
 .Choose-head{
    padding: 4%;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.Choose-head h2{
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 20px;
}

.Choose-head img{
    float: right;
    width: 25%;
}

.Choose-head .trips-1{
    color: #386B33;
    font-family: 'The Friday Stroke Regular';
    font-weight: 400;
    margin-left: 0;
 }

 #trip-grp{
    display: 100%;
}

.tripImg{
    width: 100%;
}

 .temple-back{
   width: 100%;
   height: 200px;
}



.temple-back .center-text{
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    color: white;
    margin: 0;
    position: relative;
    top: 25%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.center-text h2{
    font-size: 20px;

}

.center-text span{
    font-family: 'The Friday Stroke Regular';
    font-weight: 400;
    color: #FFD80D;
}

.center-text img{
    width: 25%;
    margin-left: 40%;
    margin-top: -8%;
}

.upcoming-head{
    width: 63%;
}

.upcoming-head h2{
    font-size: 20px;
}

.upcoming-head img{
   float: right;
   width: 30%;
}

.district{
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding-top: 3%;
 }

 .district img{
    height: 30px;
 }

 .district-para{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
 }

 .flare{
    width:100%;
    height: 300px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 0px; 

}

.flare video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;

}

.road {
    width: 100%;
    overflow: auto; /* or overflow: scroll; */
}

.road img {
    width: 600px;
    padding: 5%;
}

.right-section{
    margin: 3%;
    width:95%;
}
.tour-detail{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 4%;
    font-family: 'Prompt', sans-serif;
    font-weight: 100;
 }

 .tour-detail p{
    text-align: left;
 }

 .main-sec{
    display: block;
    border: 1px solid #bdbbbb;
    margin: 4%;
    border-radius: 10px;
 }
 .h{
    height: 200px;
 }

 .h img{
    height: 100%;
    object-fit: cover;
 }

.Tour-package{
   width: 80%;

}

 .thumbnail{
    width: 100%;
    height: 240px;
    border-radius: 5px;
 }

 

 .content-right h2{
    font-size: 20px;
   }
  
   .content-right p{
      font-size: 14px;
   }
  
   .days{
      color: #386B33;
      font-weight: bold;
   }
  
   .price{
      color: #386B33;
      font-weight: bold;
      font-size: 30px;
   }
  
   .persons{
      color: #727272;
      font-size: 12px;
   }
  
   .wrapper{
    display: flex;
    flex-direction: column-reverse;
}

 .left-section{
    margin-left: auto;
    margin-right: auto;
    width: 9 0%;
 }

 .review-img {
   width: 80%;
}

 .customer-img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
}

.customer-img h6 {
    position: absolute;
    top: 45%; 
  
    font-size: 20px;
    color: white;
}

.customer-img img{
  width: 100%;
}

.feed-back{
   width: auto;
}


.contact-section{
    padding: 5%;
}

.contact-section h2{
    width: 100%;
    padding-top: 15%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;

}
.whatsapp-section {
    padding-bottom: 15%;
}

.whatsapp-section button {
    width: 200px;
   height: 50px;
}
/* footer section */


.footer{
   padding: 10%;
   margin-left: auto;
   margin-right: auto;
}

.footer-des{
    padding-top: 4%;
    width: 100%;
    font-size: 14px;
}

.quick-links{
    padding-top: 10%;
    width: 100%;
}

.Services{
    padding-top: 3%;
    width: 100%;
    color: #F8F8F8;
}
.address p{
    width: 100%;
    text-align: left;
    font-weight: 100;
    font-size: 14px;
    font-family: 'Prompt', sans-serif;
    color: #F8F8F8;
}


.policy{
    padding-top:5% ;
   display: block; 
   text-align: center;

}

.copy-rights p{
    font-size: 12px;
    color: white;
}

 .box-layout {
        /* margin-left: 22%; */
        /* display: none; */
        /* margin-right:20%; */
        /* background-color: aqua; */
        margin-bottom: 10%;
    }

    .next-trips-thumbnail {
        width: 100%;
        height: 320px !important;
    }

    .next-trips-title {
        text-align: center;
        padding: 0% !important;
        /* background-color: black; */
    }

    .contact-button {
        /* width: auto; */
        /* padding: 2% !important; */
        font-size: 18px;
        /* box-shadow: 0px 10px 5px 0px; */
    }

    .badge-bg {
        height: auto;
        /* width: 60px; */
        z-index: 10;
        /* margin-top: 0.01%;
        margin-right: 26.5%; */
        /* background-color: bisque; */
    }

    .vehicle-img {
        z-index: 30;
        max-height: 20px;
        justify-content: center;
        align-items: center;
        margin-right: 12% !important;
        margin-top: 3% !important;
        /* background-color: antiquewhite; */
    }

    .next-trips-amt {
        width: 27% !important;
        text-align: center;
        margin-top: 0.5%;
        font-size: 26px !important;
        /* margin-left: ; */

    }

    .tour-vector {
        margin-top: -20px;
        margin-left: -20px !important;
        font-size: 22px;
        width: 100%;
                /* background-color: antiquewhite; */

    }

    .next-trips-section {
        width: 100%;
        overflow-x: hidden;
        padding: 10%;

        /* margin-left: 22%; */
        /* background-image: url('path/to/your/image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;  */
    }

    .Popular-head-1 {
        padding: 2%;
        width: 60% !important;
    }

    .Popular-head-1 h2 {
        font-family: "Prompt", sans-serif;
        font-weight: 600;
        font-size: 18px;
    }

    .Popular-head-1 img {
        margin-left: 20%;
        margin-top: -10%;
        width: 100%;
    }
   

    .img-container1 {
        padding: 0;
    }

   .img-container1 p{
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

   }

    .our-gallery-section button {
        width: 70%;
    }

    .our-gallery-images {
        /* margin-: 10%; */
        width: 100%;
        text-align: center;
        margin: 0%;
        /* background-color: aqua; */
}

.whatsapp-section2 button {
    width: 200px;
   height: 50px;
}

}
@media screen and (min-width:481px) and (max-width:767px) {
    .next-trips-section {
        font-size: 18px !important;
    }

    .next-trips-amt {
        width: 16.666667% !important;
        font-size: 18px !important;
        /* height: 5%; */
    }

    .flare{
        width:100%;
        padding: 5%;
        height: 270px;
    }

   

}
    